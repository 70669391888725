@font-face {
  font-family: 'Oswald';
  src: url('./fonts/oswald/Oswald-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('./fonts/bebasNeue/BebasNeue-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/* @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@400&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:wght@400;700&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap'); */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin:0;
  padding:0;
  height:100%;
  background-color: #f3f2e7;
  @apply leading-[normal] m-0;
}

.bg-two {
  background-image: url('../public/image-20@2x.png');
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

